import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import "./App.css";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Post from "./pages/Post";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto"; // Defaults to weight 400.

import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#561044",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#EOD68A",
      main: "#CB9173",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: "#ffcc00",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="post/:postId" element={<Post />} />
              <Route path="404" element={<NotFound />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Router>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
