import React from 'react';
import PostList from '../components/PostList';


const Home = () => {
  return (
    <div className='home'>      
      <PostList/>
    </div>
  );
};

export default Home;
