import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';


const Layout = ({ children }) => {
  return (
    <Container maxWidth={false} disableGutters sx={{minHeight:'100VH', display:'flex', flexFlow:'column'}}>
      <Header />
      <Container maxWidth='lg' sx={{flexGrow:'1'}}>
        <div className='body'><Outlet /></div>
      </Container>
      <Footer />
    </Container>
  );
};

export default Layout;
