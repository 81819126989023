import React from 'react';
import aboutContent from '../pages.json';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const About = () => {
  return (
    <div className='about'>
      <h1>This is the About Page.</h1>
      <div className='page-content'>
        <Markdown rehypePlugins={[rehypeRaw]} children={aboutContent['about'].content}/>
      </div>
    </div>
    
  );
}

export default About;