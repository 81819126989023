import React from 'react';
import { Navigate } from 'react-router-dom';
import postList from '../posts.json';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import '../components/post.scss';
import ImageRenderer from '../markdown/ImageRenderer';
import PostMetadataDetail from '../components/PostMetadataDetail';

const Post = () => {
  let params = useParams();
  
  const fetchedPost = postList.find(
    (post) => `${post.id}` === `${params.postId}`
  );

  if (!fetchedPost) {
    return <Navigate to='/404' />;
  }
  const renderPost = {
    title: fetchedPost.title ? fetchedPost.title : 'No title provided',
    author: fetchedPost.author ? fetchedPost.author : 'No author provided',
    date: fetchedPost.date ? fetchedPost.date : 'No date provided',
    content: fetchedPost.content ? fetchedPost.content : 'No content provided',
  };

  return (
    <div className='post'>
      <h1>{renderPost.title}</h1>
      <small>
        Published by {renderPost.author} on {renderPost.date}
      </small>
      {/* <hr /> */}
      <PostMetadataDetail post={fetchedPost}/>
      {/* <hr/> */}
      <div className='postcontent'>
        <Markdown rehypePlugins={[rehypeRaw]} components={{img: ImageRenderer}} children={renderPost.content} />
      </div>
    </div>
  );
};


export default Post;
