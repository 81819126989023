import React from 'react';
import { SITE_NAME } from '../util/globals';
import { NavLink, Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useScrollTrigger from '@mui/material/useScrollTrigger';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const Header = (props) => {
  return (
    <React.Fragment>
    <ElevationScroll {...props}>
      <AppBar position='fixed'>
        <Container maxWidth='xl'>
          <Toolbar disableGutters sx={{justifyContent:'space-between'}}>
            <Typography sx={{color:'white',textDecoration:'none',}} variant='h5' noWrap component={Link} to='/'>
              {SITE_NAME}
            </Typography>
            <Box >
              <Button
                component={NavLink}
                sx={{ my: 2, color: 'white', display: 'block' }}
                to='/about'>
                About
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
    <Toolbar />
    </React.Fragment>
  );
};

export default Header;
