import React from 'react';
import Markdown from 'react-markdown';
import postlist from '../posts.json';
import {Link} from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import ImageRenderer from '../markdown/ImageRenderer';



const PostList = () => {
  return (
    <div className='postlist'>
      <h1 className='title'>All Posts</h1>
      {
        postlist.sort((a,b) => b.timestamp - a.timestamp).map((post,i) => <PostCard key={post.id} post={post} index={post.id}/>)
      }
    </div>
  );
}

const PostCard = ({post, index}) => {
  const words = post.content.split(' ');
  const excerpt = `${words.slice(0,80).join(' ')}&hellip;`;

  return (
    <div className='post-card'>
      <h2><Link className='links' to={`/post/${post.id}`}>{post.title}</Link></h2>
      <small>Published on {post.date} by {post.author}</small>
      <hr/>
      <Markdown rehypePlugins={[rehypeRaw]} components={{img: ImageRenderer}} children={excerpt} />
      <small><Link className='links' to={`/post/${post.id}`}>read more&hellip;</Link></small>
    </div>
  )
}

export default PostList;