import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, value, type='text', secondaryText='') {
  return { name, value, type, secondaryText };
}

const createRows = (post) => [
  createData("Game", post.game),
  createData("Designer", post.designer),
  createData("Publisher", post.publisher),
  createData("BGG Link", `https://www.boardgamegeek.com/boardgame/${post.bggId}/${post.game}`, 'link', 'BoardGameGeek')
];

export default function PostMetadataDetail({ post }) {
  const rows = createRows(post);
  return (
    // <TableContainer component={Paper}>
      <Table sx={{width: 'auto', minWidth: '300px'}} aria-label="customized table">
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" sx={{fontWeight:'bold'}}>
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{ row.type === 'link' ? <a href={row.value}>{row.secondaryText}</a> : row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    // </TableContainer>
  );
}
