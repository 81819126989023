import React from 'react';

const NotFound = () => {
  return (
    <h1 className='notfound'>
      The page you are looking for could not be found...
    </h1>
  );
};

export default NotFound;
